import React from 'react';
import AnimatedItem from './AnimatedItem';

const GamesSection = () => {
  return (
    <div className="flex flex-col items-center justify-start w-full min-h-screen p-4 max-w-screen lg:px-10 bg-main">
      <AnimatedItem>
        <div className="p-8 text-4xl font-black text-center text-white lg:max-w-2xl lg:relative lg:mx-auto rounded-xl bg-accent">My Games!</div>
      </AnimatedItem>
      <div className='grid xl:grid-cols-3 xl:gap-4'>
        <GameAnimatedItem>
          <iframe src="https://itch.io/embed/1411096" className='w-[208px] md:w-[556px] xl:w-screen' height="167"><a href="https://aptos.itch.io/no-bones-inc">No Bones Inc. by aptos, ixelp, StrongTea</a></iframe>
        </GameAnimatedItem>
        <GameAnimatedItem>
          <iframe src="https://itch.io/embed/1365755" className='w-[208px] md:w-[556px] xl:w-screen' height="167"><a href="https://aptos.itch.io/delivery">Delivery! by aptos, alexihill</a></iframe>
        </GameAnimatedItem>
        <GameAnimatedItem>
          <iframe src="https://itch.io/embed/1212097" className='w-[208px] md:w-[556px] xl:w-screen' height="167"><a href="https://aptos.itch.io/enduring-days">Enduring Days by aptos, ixelp</a></iframe>
        </GameAnimatedItem>
        <GameAnimatedItem>
          <iframe src="https://itch.io/embed/1144343" className='w-[208px] md:w-[556px] xl:w-screen' height="167"><a href="https://aptos.itch.io/hectic-hop">Hectic Hop by aptos, ixelp, kysokyso, jorzante, PellyRax</a></iframe>
        </GameAnimatedItem>
        <GameAnimatedItem>
          <iframe src="https://itch.io/embed/1119641" className='w-[208px] md:w-[556px] xl:w-screen' height="167"><a href="https://aptos.itch.io/moving-out">Moving Day by aptos, StrongTea, jorzante</a></iframe>
        </GameAnimatedItem>
        <GameAnimatedItem>
          <iframe src="https://itch.io/embed/1096330" className='w-[208px] md:w-[556px] xl:w-screen' height="167"><a href="https://aptos.itch.io/orbito">Orbito by aptos</a></iframe>
        </GameAnimatedItem>
        <GameAnimatedItem>
          <iframe src="https://itch.io/embed/1061207" className='w-[208px] md:w-[556px] xl:w-screen' height="167"><a href="https://aptos.itch.io/grappler">Grappler by aptos</a></iframe>
        </GameAnimatedItem>
        <GameAnimatedItem>
          <iframe src="https://itch.io/embed/1052706" className='w-[208px] md:w-[556px] xl:w-screen' height="167"><a href="https://aptos.itch.io/chance-of-chivalry">Chance of Chivalry by aptos, jorzante</a></iframe>
        </GameAnimatedItem>
        <GameAnimatedItem>
          <iframe src="https://itch.io/embed/911441" className='w-[208px] md:w-[556px] xl:w-screen' height="167"><a href="https://brick-wall-games.itch.io/split-in-three">Split In Three by Brick Wall Games, blazeonfire, aptos, SpadgersHat</a></iframe>
        </GameAnimatedItem>
      </div>
    </div>
  )
}

const GameAnimatedItem = ({ children }) => <div className="flex justify-center w-full mt-5">{children}</div>

export default GamesSection;