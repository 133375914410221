import React from 'react';
import AnimatedItem from './AnimatedItem';

const Section = () => {
  return (
    <div className="flex flex-col items-center justify-start w-full min-h-screen p-4 max-w-screen lg:px-10 bg-light">
      <AnimatedItem>
        <div className="p-8 text-4xl font-black text-center text-white lg:max-w-2xl lg:relative lg:mx-auto rounded-xl bg-accent">About Me</div>
      </AnimatedItem>
      <AnimatedItem parentClasses='flex w-full flex-col' >
        <DetailCard 
          side="right" 
          title="I'm a full-stack developer" 
        >
          <div className='mb-2 text-lg'>I enjoy bringing ideas to life. My experience includes developing modern web apps, mobile apps, and backend systems.</div>
          <div className='mb-2 text-lg'>
            My current strengths are in <span className='font-black'>React</span> and <span className='font-black'>React Native</span>.
          </div>
          <div className='mb-2 text-lg'>I can work off of mockups to create UI elements in HTML and CSS, as well as integrate backend systems through REST APIs or GraphQL</div>
        </DetailCard>
      </AnimatedItem>
      <AnimatedItem parentClasses='flex w-full flex-col' >
        <DetailCard side="left" title="I'm passionate about music and game development">
          <div className='mb-2 text-lg'>In my spare time, I love developing my own videogames.</div>
          <div className='mb-2 text-lg'>
            I find it to be a great way to express myself creatively in a variety of mediums. I've designed levels, practiced UI/UX, composed music, and more!
          </div>
          <div className='mb-2 text-lg'>I also play instruments and collect physical media from my favorite artists.</div>
        </DetailCard>
      </AnimatedItem>
    </div>
  )
}

const DetailCard = ({ side="left", title, children }) => {
  return (
    <div className={`relative p-8 mt-4 text-white lg:max-w-2xl lg:p-12 bg-accent self-center lg:${side}-32 w-full rounded-xl`}>
      <div className='mb-4 text-4xl font-bold text-light'>{title}</div>
      {children}
    </div>
  )
}



export default Section