import React from 'react';
import { ReactSVG } from 'react-svg';
import { useTransition,
  useSpring,
  useChain,
  config,
  easings,
  animated,
  useSpringRef } from 'react-spring';

const getRandomInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min);
}

const Blob = ({ out = true }) => {
  const randomStart = getRandomInt(22, 30)
  const randomEnd = out ? getRandomInt(4, 18) : getRandomInt(32, 45)
  const randomDuration = getRandomInt(1000, 2000)

  const spring = useSpring({
    from: { x: randomStart },
    to: [{ x: randomEnd }, {x: randomStart}],
    loop: true,
    // config: { ...config.gentle, duration: randomDuration }
    config: { easing: easings.easeInOutExpo , duration: randomDuration}
  })

  return (
    <animated.div style={spring} >
      <ReactSVG
        className="invisible lg:visible lg:w-36"
        src={out ? "round-out-2.svg" : "round-in-2.svg"}
      />
    </animated.div>
  )
}

const SVGItem = () => {
  const amount = window.innerHeight / 80
  const items = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]
  return (
    <div className='absolute right-0 h-screen overflow-hidden'>
      {items.map((i, index) => <Blob out={index % 2 !== 0} />)}
    </div>
  )
}

export default SVGItem;