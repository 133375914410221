import React from 'react';
import me from './Images/me.png'
import './App.css';
import { useTransition,
  useSpring,
  useChain,
  config,
  animated,
  useSpringRef } from 'react-spring';

import Section from './Components/Section';
import SVGItem from './Components/SVGTest';
import GamesSection from './Components/GamesSection';

function App() {
  return (
    <div className='overflow-hidden'>
      <div className="flex items-center">
        <header className="flex w-full h-screen overflow-hidden bg-main">
          <div className='w-0 min-h-screen lg:w-1/3 bg-accent'></div>
          <AnimatedTitle />
          <SVGItem />
        </header>
      </div>
      <Section />
      <GamesSection />
      <div className='flex flex-col items-center justify-center w-full py-10 bg-accent'>
        <div className='my-4 text-4xl font-black text-white'>Contact</div>
        <a href="https://www.linkedin.com/in/jordan-fite-ab2411114/" className='mb-4 font-bold text-white underline'>LinkedIn</a>
        <a href="https://github.com/JordanTFite" className='mb-4 font-bold text-white underline'>Github</a>
      </div>
    </div>
  );
}

const AnimatedTitle = () => {
  const springRef = useSpringRef();
  const containerSpring = useSpring({ 
    from: { y: 100, scale: 0 },
    to: { y: 0, scale: 1 },
    config: config.stiff,
    ref: springRef,
    delay: 1000
  })

  const transitionRef = useSpringRef();
  const transitions = useTransition(null, {
    ref: transitionRef,
    from: { opacity: 0, scale: 0 },
    enter: { opacity: 1, scale: 1 },
    config: config.stiff
  })

  const imageRef = useSpringRef();
  const imageTransition = useTransition(null, {
    from: { opacity: 0, scale: 0 },
    enter: { opacity: 1, scale: 1 },
    ref: imageRef,
    config: config.stiff
  })

  useChain([springRef, transitionRef, imageRef])

  return (
    <div className='flex flex-col justify-center w-full m-2 lg:w-auto'>
      <animated.div className="relative self-center w-full p-1 py-8 m-2 border-8 lg:w-auto rounded-3xl lg:m-0 lg:p-20 bg-accent border-main lg:right-40 lg:bottom-0 bottom-16" style={containerSpring}>
          {transitions(style => (
            <animated.div style={style}>
              <p style={style} className="font-black text-center text-white lg:text-left text-7xl">Jordan Fite</p>
              <p style={style} className="text-4xl font-thin text-center text-white lg:text-left">Software Engineer</p>
            </animated.div>
          ))}
          {imageTransition(style => (
            <animated.img 
              src={me} 
              alt="Me" 
              style={style} 
              className='absolute invisible w-auto border-8 rounded-full lg:visible lg:left-auto border-main lg:-bottom-20 w-60 h-60 lg:-right-40'
            />
          ))}
      </animated.div>
      <div className='flex items-center justify-center w-full'>
        {imageTransition(style => (
          <animated.img 
            src={me} 
            alt="Me" 
            style={style} 
            className='relative visible w-auto border-8 rounded-full lg:invisible lg:visible lg:left-auto border-main lg:-bottom-20 w-60 h-60 lg:-right-40'
          />
        ))}
      </div>
    </div>
  )
}

export default App;
