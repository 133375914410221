import React, { useRef } from 'react';
import useOnScreen from '../Hooks/useOnScreen';
import { useTransition,
  useSpring,
  useChain,
  config,
  animated,
  useSpringRef } from 'react-spring';

const AnimatedItem = ({ children, parentClasses = "", slide = false}) => {
  const ref = useRef();
  const visible = useOnScreen(ref);

  let defaultConfig = { 
    from: {scale: visible ? 0 : 1, opacity: visible ? 0 : 1 },
    to: {scale: visible ? 1 : 0, opacity: visible ? 1 : 0 },
    config: config.gentle,
    delay: 300
  }
  if (slide) {
    defaultConfig = { 
      from: {x: visible ? -100 : 0, opacity: visible ? 0 : 1 },
      to: {x: visible ? 0 : -100, opacity: visible ? 1 : 0 },
      config: config.stiff,
      delay: 300
    }
  }

  const animationStyle = useSpring(defaultConfig)

  return (
    <animated.div style={animationStyle} ref={ref} className={`${parentClasses} w-full`}>
      {children}
    </animated.div>
  )
}

export default AnimatedItem;